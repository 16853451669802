import { Fragment, useEffect, useState } from "react";
import { ILastResult, scoreboardProvider } from "../data/scoreboard";
import hdLogo from "../images/HawkinDynamics_Logo.svg";
import logo from "../images/gatorade_logo.png";
import { IScoreboardRow, ScoreboardRow } from "./ScoreboardRow";
import { scoreFormatter } from "./scoreFormatter";
import { lastParticipantNameFormatter } from "./lastParticipantNameFormatter";

function Scoreboard() {
  const [lastResult, setLastResult] = useState<ILastResult | null>(null);
  const [mensScores, setMensScores] = useState<IScoreboardRow[]>([]);
  const [womensScores, setWomensScores] = useState<IScoreboardRow[]>([]);

  useEffect(() => {
    const subscriber = scoreboardProvider().subscribe(
      (scoreboard) => {
        if (scoreboard !== null) {
          setMensScores(scoreboard.male);
          setWomensScores(scoreboard.female);
          setLastResult(scoreboard.lastResult);
        }
      },
      (error) => {
        console.error(error);
      }
    );
    return () => subscriber.unsubscribe();
  }, []);

  const leaderboardStart = process.env.REACT_APP_LEADERBOARD_START
    ? parseInt(process.env.REACT_APP_LEADERBOARD_START)
    : 0;

  const leaderboardEnd = process.env.REACT_APP_LEADERBOARD_END
    ? parseInt(process.env.REACT_APP_LEADERBOARD_END)
    : 0;

  const isLeaderboardOpen = () => {
    let now = Date.now();
    return now >= leaderboardStart && now < leaderboardEnd;
  };

  const showFinalFinishers = () => {
    let now = Date.now();
    return !isLeaderboardOpen() && now > leaderboardEnd;
  };

  return (
    <Fragment>
      <div className="App-Section Header">
        <div className="Last-Score">
          <div className="Last-Participant">
            {lastResult?.name
              ? lastParticipantNameFormatter(lastResult?.name)
              : null}
          </div>
          <div className="Score">
            {lastResult ? (
              <Fragment>
                <div className="Score-Value">
                  {lastResult?.score ? scoreFormatter(lastResult.score) : null}
                </div>
                <div className="Score-Units">W/kg</div>
              </Fragment>
            ) : null}
          </div>
          <div className="Brand">
            <img
              src={logo}
              className="Logo"
              alt="Gatorade Performance Partner"
            />
            <div className="Powered-By">POWERED BY:</div>
            <img src={hdLogo} className="HDLogo" alt="Hawkin Dynamics" />
          </div>
          {showFinalFinishers() && (
            <div className="Header-Label">
              {"Final Top Finishers:".toUpperCase()}
            </div>
          )}
        </div>
      </div>

      <div className="App-Section">
        <div className="Scoreboard-Label App-Section-Child">Female</div>
        <div className="Scoreboard-Table App-Section-Child">
          <table className="Table">
            <tbody className="Table-Body">
              {womensScores.map((score, index) => {
                return <ScoreboardRow rank={index} {...score} key={score.id} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="App-Section">
        <div className="Scoreboard-Label">Male</div>
        <div className="Scoreboard-Table">
          <table className="Table">
            <tbody className="Table-Body">
              {mensScores.map((score, index) => {
                return <ScoreboardRow rank={index} {...score} key={score.id} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
}

export default Scoreboard;
