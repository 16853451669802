export const lastParticipantNameFormatter = (full_name: string) => {
  var formatted_name = full_name.trim();
  try {
    return formatted_name
    .toLowerCase()
    .split(" ")
    .map(function (name_part) {
      return name_part.replace(name_part[0], name_part[0].toUpperCase());
    })
    .join(" ");
  } catch (error) {
    // Log error in TrackJS
    (window as any)['TrackJS'].track(error);
    // Give up and move on if we can't properly format
    return full_name;
  }
};
