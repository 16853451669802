import { useEffect, useState } from "react";
import "./App.css";
import { Login } from "./components/Login";
import Scoreboard from "./components/Scoreboard";
import { auth$ } from "./data/auth";

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(() => {
    const subscriber = auth$.subscribe((auth) => {
      if (auth) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    });
    return () => subscriber.unsubscribe();
  }, []);
  return <div className="App">{isSignedIn ? <Scoreboard /> : <Login />}</div>;
}

export default App;
