import {
  DocumentData,
  collection,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import sortBy from "lodash.sortby";
import { collectionData } from "rxfire/firestore";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { db } from "./firebase";
import { round } from "./round";

export interface ILastResult {
  name: string;
  score: number;
}

export interface IScoreboardRow {
  id: string;
  name: string;
  score: number;
}

export interface IRank {
  rank: number;
}

export interface IScoreboard {
  female: IScoreboardRow[];
  male: IScoreboardRow[];
  lastResult: ILastResult;
}

export interface IScoreboardRawData {
  lastResult: DocumentData;
  female: DocumentData[];
  male: DocumentData[];
}

const FEMALE_GROUP_ID = process.env.REACT_APP_FEMALE_GROUP_ID
  ? process.env.REACT_APP_FEMALE_GROUP_ID
  : "not-set";
const MALE_GROUP_ID = process.env.REACT_APP_MALE_GROUP_ID
  ? process.env.REACT_APP_MALE_GROUP_ID
  : "not-set";
const METRIC_ID = process.env.REACT_APP_METRIC_ID
  ? process.env.REACT_APP_METRIC_ID
  : "not-set";
const ORG_ID = process.env.REACT_APP_ORG_ID
  ? process.env.REACT_APP_ORG_ID
  : "not-set";
const TEAM_ID = process.env.REACT_APP_TEAM_ID
  ? process.env.REACT_APP_TEAM_ID
  : "not-set";
const TEST_TYPE = process.env.REACT_APP_TEST_TYPE
  ? process.env.REACT_APP_TEST_TYPE
  : "not-set";
const LEADERBOARD_START = process.env.REACT_APP_LEADERBOARD_START
  ? parseInt(process.env.REACT_APP_LEADERBOARD_START)
  : 0;
const LEADERBOARD_END = process.env.REACT_APP_LEADERBOARD_END
  ? parseInt(process.env.REACT_APP_LEADERBOARD_END)
  : 0;

const athleteNameProvider = (): Observable<Map<string, string> | null> => {
  return collectionData(
    query(
      collection(db, `orgs/${ORG_ID}/athletes`),
      where("teams", "array-contains", TEAM_ID)
    ),
    {
      idField: "id",
    }
  ).pipe(
    map((athletes) => {
      if (athletes.length === 0) return null;
      const athleteNameLookup = new Map<string, string>();
      athletes.forEach((athlete) => {
        athleteNameLookup.set(athlete.id, athlete.name);
      });
      return athleteNameLookup;
    })
  );
};

const scoreboardDataProvider = (): Observable<IScoreboardRawData | null> => {
  const collectionRef = collection(db, `orgs/${ORG_ID}/tests`);
  const queryRef = query(
    collectionRef,
    where("active", "==", true),
    where("teams", "array-contains", TEAM_ID),
    where("testType", "==", TEST_TYPE),
    orderBy("timestamp", "asc")
  );
  return collectionData(queryRef, { idField: "id" }).pipe(
    map((rows) => {
      if (rows.length === 0) return null;
      const lastResult = rows[rows.length - 1];
      let topMale: DocumentData[] = [];
      let topFemale: DocumentData[] = [];

      for (const test of rows) {
        if (test.groups?.includes(FEMALE_GROUP_ID)) {
          topFemale.push(test);
        } else if (test.groups?.includes(MALE_GROUP_ID)) {
          topMale.push(test);
        }
      }

      const [male, female] = pickTopFive(
        topMale,
        topFemale,
        LEADERBOARD_START,
        LEADERBOARD_END
      );

      return {
        lastResult,
        male,
        female,
      };
    })
  );
};

const pickTopFive = (
  male: DocumentData[],
  female: DocumentData[],
  period_start: number,
  period_end: number
) => {
  const topMale = new Map<string, DocumentData>();
  const topFemale = new Map<string, DocumentData>();
  const sortedMale = sortBy(male, (o) => -o[METRIC_ID]);
  const sortedFemale = sortBy(female, (o) => -o[METRIC_ID]);
  for (const trial of sortedMale) {
    const previousAthleteTrial = topMale.get(trial.athleteId);
    if (previousAthleteTrial) {
      continue;
    } else if (trial.timestamp < period_start || trial.timestamp > period_end) {
      continue;
    } else {
      topMale.set(trial.athleteId, trial);
    }
    if (topMale.size >= 5) break;
  }
  for (const trial of sortedFemale) {
    const previousAthleteTrial = topFemale.get(trial.athleteId);
    if (previousAthleteTrial) {
      continue;
    } else if (
      trial.timestamp <= period_start ||
      trial.timestamp >= period_end
    ) {
      continue;
    } else {
      topFemale.set(trial.athleteId, trial);
    }
    if (topFemale.size >= 5) break;
  }
  return [Array.from(topMale.values()), Array.from(topFemale.values())];
};

export const scoreboardProvider = (): Observable<IScoreboard | null> => {
  return combineLatest([scoreboardDataProvider(), athleteNameProvider()]).pipe(
    map((results) => {
      const [data, athleteNameLookup] = results;
      if (!data || !athleteNameLookup) return null;
      return {
        lastResult: {
          id: data.lastResult.id,
          name: athleteNameLookup.get(data.lastResult.athleteId) as string,
          score: round(data.lastResult[METRIC_ID], 2),
        },
        female: data.female.map(
          (row) =>
            ({
              id: row.id,
              name: athleteNameLookup.get(row.athleteId) as string,
              score: round(row[METRIC_ID], 2),
            } as IScoreboardRow)
        ),

        male: data.male.map(
          (row) =>
            ({
              id: row.id,
              name: athleteNameLookup.get(row.athleteId) as string,
              score: round(row[METRIC_ID], 2),
            } as IScoreboardRow)
        ),
      };
    })
  );
};
