import { FirebaseError } from "firebase/app";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Fragment, useState } from "react";
import { auth } from "../data/firebase";
import logo from "../images/gatorade_logo.png";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      setError((e as FirebaseError).code.split("/")[1]);
    }
  };
  return (
    <Fragment>
      <div className="Login">
        <img src={logo} className="Logo" alt="Gatorade Performance Partner" />
        <form className="Login-Form" onSubmit={onSubmit}>
          <input
            type="text"
            className="Login-Input"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            className="Login-Input"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="Login-Button">
            Sign In
          </button>
          {error ? <div className="Login-Error">{error}</div> : null}
        </form>
      </div>
    </Fragment>
  );
}
