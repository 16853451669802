export const scoreFormatter = (score: number) => {
  const parts = score.toString().split(".");
  let [major, minor] = parts;
  if (!minor) {
    minor = "00";
  }
  if (!major) {
    major = "00";
  }
  if (major && major.length === 1) {
    major = String(major).padStart(2, "0");
  }
  if (minor && minor.length === 1) {
    minor = String(minor).padEnd(2, "0");
  }
  return `${major}.${minor}`;
};
