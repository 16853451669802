import { scoreFormatter } from "./scoreFormatter";

export interface IScoreboardRow {
  id: string;
  name: string;
  score: number;
}

export interface IRank {
  rank: number;
}

export function ScoreboardRow(props: IScoreboardRow & IRank) {
  return (
    <tr className="Table-Row">
      <td className="Participant-Rank">{props.rank + 1}</td>
      <td className="Participant-Name">{props.name}</td>
      <td className="Participant-Score">
        <div className="">{scoreFormatter(props.score)}</div>
      </td>
    </tr>
  );
}
