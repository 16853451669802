import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const usConfig = {
  apiKey: "AIzaSyC_nrfyPK7xtZz8E9mhbt1q_NjHDaK1KIU",
  authDomain: "hawkin-firestore-wtr.firebaseapp.com",
  databaseURL: "https://hawkin-firestore-wtr.firebaseio.com",
  projectId: "hawkin-firestore-wtr",
  storageBucket: "hawkin-firestore-wtr.appspot.com",
  messagingSenderId: "1036552922435",
  appId: "1:1036552922435:web:75f7a7bbf099e031",
};

const app = initializeApp(usConfig, "us");
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
